import React from 'react'

const Hero = () => (
  <section className="hero">
    <h1 className="hero__title text--dark-background">
      Somos Soluciones en Tecnología e Información del Noroeste
    </h1>
    <h2 className="hero__subtitle text--dark-background">
      Ofrecemos soluciones integrales en tecnologías de la información para su
      negocio.
    </h2>
  </section>
)

export default Hero
