import React, { Component } from 'react'
import Link from 'gatsby-link'

export default class Service extends Component {
  static defaultProps = {
    url: '',
    color: '',
    title: '',
    image: '',
  }

  constructor(props) {
    super(props)
  }
  render() {
    if (this.props.url !== '') {
      return (
        <div>
          <div className="service--image-container">
            <Link to={this.props.url}>
              <img className="service--image" src={this.props.image} />
            </Link>
          </div>
          <div className="column is-narrow">
            <Link to={this.props.url}>
              <h2 className="white-text service--title">{this.props.title}</h2>
            </Link>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="service--image-container">
            <img className="service--image" src={this.props.image} />
          </div>
          <div className="column is-narrow">
            <h2 className="white-text service--title">{this.props.title}</h2>
          </div>
        </div>
      )
    }
  }
}
