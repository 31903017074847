import React from 'react'

import Hero from '../components/Index/Hero'
import Services from '../components/Index/Services'
import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    <div>
      <Hero />
      <Services />
    </div>
  </Layout>
)

export default IndexPage
