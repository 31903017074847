import React, { Component } from 'react'
import Service from './Service'

import aplicaciones from '../../static/aplicaciones.svg'
import soporte from '../../static/soporte.svg'
import facturacion from '../../static/facturacion.svg'
import senalizacion from '../../static/senalizacion.svg'
import preciadores from '../../static/preciadores.svg'

export default class Services extends Component {
  render() {
    return (
      <section className="services">
        <h1 className="title is-1 has-text-centered margin-bottom-large dark-grey ">
          Conoce nuestros servicios
        </h1>
        <div className="services--grid">
          <Service
            title="Desarrollo de aplicaciones moviles y web"
            image={aplicaciones}
          />
          <Service title="Soporte Técnico" image={soporte} />
          <Service
            title="Facturación Electrónica"
            url="/facturacion"
            image={facturacion}
          />
          <Service title="Digital Signage" image={senalizacion} />
          <Service title="Infraestructura" image={preciadores} />
        </div>
      </section>
    )
  }
}
